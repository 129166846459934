(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("msgpack5"), require("signalR"));
	else if(typeof define === 'function' && define.amd)
		define(["msgpack5", "signalR"], factory);
	else if(typeof exports === 'object')
		exports["msgpack"] = factory(require("msgpack5"), require("signalR"));
	else
		root["signalR"] = root["signalR"] || {}, root["signalR"]["protocols"] = root["signalR"]["protocols"] || {}, root["signalR"]["protocols"]["msgpack"] = factory(root["msgpack5"], root["signalR"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 